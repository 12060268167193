import {
  Bengali,
  Catalonia,
  Chinese,
  Denmark,
  English,
  Finland,
  French,
  German,
  Greece,
  Hungary,
  India,
  Indonesian,
  Italian,
  Japanese,
  Korea,
  Lithuania,
  Malay,
  Netherlands,
  Pakistan,
  Philippines,
  Poland,
  Portuguese,
  Russian,
  SaudiArabia,
  Serbia,
  Slovakia,
  SouthAfrica,
  Spanish,
  Swahili,
  Swedish,
  Thai,
  Turkish,
  Ukrainian,
  Vietnamese,
} from '@rango-dev/ui';

export const LANGUAGES = [
  { name: 'Afrikaans', value: 'af', Icon: SouthAfrica },
  { name: 'Arabic', value: 'ar', Icon: SaudiArabia },
  { name: 'Bengali', value: 'bn', Icon: Bengali },
  { name: 'Catalan', value: 'ca', Icon: Catalonia },
  { name: 'Chinese (Simplified)', value: 'zh-CN', Icon: Chinese },
  { name: 'Chinese (Traditional)', value: 'zh-TW', Icon: Chinese },
  { name: 'Danish', value: 'da', Icon: Denmark },
  { name: 'Dutch', value: 'nl', Icon: Netherlands },
  { name: 'English', value: 'en', Icon: English },
  { name: 'Filipino', value: 'fil', Icon: Philippines },
  { name: 'Finnish', value: 'fi', Icon: Finland },
  { name: 'French', value: 'fr', Icon: French },
  { name: 'German', value: 'de', Icon: German },
  { name: 'Greek', value: 'el', Icon: Greece },
  { name: 'Hindi', value: 'hi', Icon: India },
  { name: 'Hungarian', value: 'hu', Icon: Hungary },
  { name: 'Indonesian', value: 'id', Icon: Indonesian },
  { name: 'Italian', value: 'it', Icon: Italian },
  { name: 'Japanese', value: 'ja', Icon: Japanese },
  { name: 'Korean', value: 'ko', Icon: Korea },
  { name: 'Lithuanian', value: 'lt', Icon: Lithuania },
  { name: 'Malay', value: 'ms', Icon: Malay },
  { name: 'Polish', value: 'pl', Icon: Poland },
  { name: 'Portuguese', value: 'pt', Icon: Portuguese },
  { name: 'Russian', value: 'ru', Icon: Russian },
  { name: 'Serbian', value: 'sr', Icon: Serbia },
  { name: 'Slovak', value: 'sk', Icon: Slovakia },
  { name: 'Spanish', value: 'es', Icon: Spanish },
  { name: 'Swahili', value: 'sw', Icon: Swahili },
  { name: 'Swedish', value: 'sv', Icon: Swedish },
  { name: 'Thai', value: 'th', Icon: Thai },
  { name: 'Turkish', value: 'tr', Icon: Turkish },
  { name: 'Ukrainian', value: 'uk', Icon: Ukrainian },
  { name: 'Urdu', value: 'ur', Icon: Pakistan },
  { name: 'Vietnamese', value: 'vi', Icon: Vietnamese },
];
